import LayoutAdmin from "@/layouts/LayoutAdmin";

export default {
  data() {
    return {
      selected: [],
      search: "",
      loadingTb: false,
      isActive: [],
      options: null,
      summary: [],
      tableOutput: [],
      listStatker: [],
      listBalai: [],
      listGiat: [],
      listEvent: [],
      listRegister: [],
      selectSatker: null,
      isTotalKodeAkun: false,
      istableOutput: false,
      isShow: false,
      setBalaiValue: null,
      setSatkerValue: null,
      setGiatValue: null,
      data_color: [
        "#00C5DC",
        "#F4516C",
        "#FFB822",
        "#8859E0",
        "#0C5484",
        "#66BB6A",
        "#00838F",
        "#e57373",
      ],
      currentNamaKelompok: "",
      list_status: [
        {
          text: "Proses",
          value: 1,
        },
        {
          text: "Selesai",
          value: 2,
        },
      ],
    };
  },
  created() {
    this.$emit(`update:layout`, LayoutAdmin);
  },
  mounted() {
    this.G_GetRef("giat").then((data) => {
      this.listGiat = data;
    });

    this.G_GetRef("register").then((data) => {
      this.listRegister = data;
      this.listRegister.unshift({
        text: "Semua Register",
        value: "all",
      });
    });

    this.getSummary();

    this.G_GetRef("balai").then((data) => {
      this.listBalai = data;

      if (this.getUser.userGroup == "balai") {
        // this.setBalaiValue = this.getUser.kdbalai
        this.$refs.balai.setValue(this.getUser.kdbalai);
      } else if (this.getUser.userGroup == "satker") {
        // this.setBalaiValue = this.getUser.kdbalai

        this.$refs.balai.setValue(this.getUser.kdbalai);
      } else {
        this.listBalai.unshift({
          text: "Semua Balai",
          value: "all",
        });
      }
    });

    this.G_GetRef("satker").then((data) => {
      this.listStatker = data;
      if (this.getUser.userGroup == "satker") {
        // this.setBalaiValue = this.getUser.kdsatker
        this.$refs.satker.setValue(this.getUser.kdsatker);
      } else {
        this.listStatker.unshift({
          text: "Semua Satker",
          value: "all",
        });
      }
    });

    // this.getSummary()
  },
  // components: {
  //   myTable,myTableBalai
  // },
  computed: {},
  methods: {
    updateStatusKelompok(kelompok) {
      this.currentNamaKelompok = kelompok.namaKelompok;

      this.$refs.modal.open("Update Status Kelompok", 600);

      this.$nextTick(() => {
        this.$refs.statusKelompok.setValue(kelompok.status);
      });
    },
    storeUpdateStatusKelompok() {


      let myForm = document.getElementById("formData");
      let formData = new FormData(myForm);

      if (this.$refs.form.validate()) {
        this.$refs.modal.loading();

        this.G_PostAny("sakti/revisi/kelompok/status", formData)
          .then((res) => {
            if (res) {
              this.getSummary();
              this.$snotify.success("Berhasil Mengubah Status");
              this.$refs.modal.close();
            } else {
              this.$snotify.warning("Terjadi Kesalahan");
            }
          })
          .finally(() => {
            this.$refs.modal.loadingEnd();
          });
      } else {
        this.$snotify.info("Lengkapi Data Terlebih Dahulu");
      }
    },
    storeBuatKelompok() {

      let myForm = document.getElementById("formDataKelompok");
      let formData = new FormData(myForm);

      formData.append("listKdSatker", this.selected);

      var param = {
        namaKelompok: formData.get("namaKelompok"),
        listKdSatker: this.selected,
      };

      if (this.$refs.formKelompok.validate()) {
        this.$refs.modalkelompok.loading();
        this.G_PostAnyCustom("sakti/revisi/kelompok", param)
          .then((res) => {
            if (res) {
              this.getSummary();
              this.$snotify.success("Berhasil membuat kelompok");
              this.$refs.modalkelompok.close();
            } else {
              this.$snotify.warning("Terjadi Kesalahan");
            }
          })
          .finally(() => {
            this.$refs.modalkelompok.loadingEnd();
          });
      } else {
        this.$snotify.info("Lengkapi Data Terlebih Dahulu");
      }
    },
    buatKelompok() {
      this.$refs.modalkelompok.open("Buat Kelompok", 600);
    },
    setSatker(id) {
      if (id != "all" || id == null) {
        this.G_GetRef("satker", "?balaiId=" + id).then((data) => {
          this.listStatker = data;
          this.listStatker.unshift({
            text: "Semua Satker",
            value: "all",
          });
        });
      } else {
        this.G_GetRef("satker").then((data) => {
          this.listStatker = data;
          this.listStatker.unshift({
            text: "Semua Satker",
            value: "all",
          });
        });
      }
    },
    toggleSub(key) {
      this.isActive[key] = !this.isActive[key];

      if (this.isActive[key]) {
        $("#sub_" + key).hide();
      } else {
        $("#sub_" + key).show();
      }

    },
    getSummary(setfilter) {
      this.isShow = true;
      this.istableOutput = true;

      let filter = [];

      if (setfilter) {
        filter = setfilter;
      } else {
        this.setBalaiValue = this.getUser.kdbalai;
        this.setBalaiValue = this.getUser.kdsatker;

        if (this.setBalaiValue) {
          if (this.setBalaiValue != "all") {
            filter.push("kdbalai=" + this.setBalaiValue);
          }
        }

        if (this.setBalaiValue) {
          if (this.setBalaiValue != "all") {
            filter.push("kdsatker=" + this.setBalaiValue);
          }
        }
      }

      filter.push("eventId=2");

      filter = "?" + filter.join("&");

      this.G_GetAny("sakti/revisi/kelompok" + filter).then((res) => {
        var content = res.data.content;

        var currentKelompok = "";
        for (var i = 0; i < content.length; i++) {
          var rowSpan = 1;
          if (
            currentKelompok != content[i].namaKelompok &&
            content[i].status != 0
          ) {
            currentKelompok = content[i].namaKelompok;
            rowSpan = content.filter(function(item) {
              return item.namaKelompok == currentKelompok;
            }).length;
            content[i].rowspan = rowSpan;
          } else {
            if (content[i].status != 0) {
              content[i].rowspan = 0;
            } else {
              content[i].rowspan = 1;
            }
          }
        }

        this.tableOutput = content;

        this.istableOutput = false;
      });
    },
    cariData() {
      let filter = [];

      if (this.$refs.satker.getValue()) {
        if (this.$refs.satker.getValue() != "all") {
          filter.push("kdsatker=" + this.$refs.satker.getValue());
        }
      }

      if (this.$refs.balai.getValue()) {
        if (this.$refs.balai.getValue() != "all") {
          filter.push("kdbalai=" + this.$refs.balai.getValue());
        }
      }

      this.getSummary(filter);
    },

    selectAll(type) {
      if (type == "kolom") {
        this.$refs.colomTable.selectAll();
      } else if (type == "giat") {
        this.$refs.giat.selectAll();
      }
    },
    removeAll(type) {
      if (type == "kolom") {
        this.$refs.colomTable.removeAll();
      } else if (type == "giat") {
        this.$refs.giat.removeAll();
      }
    },
  },
};
